.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  height: 100%;
  width: 100%;
  position: absolute;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input-group {
  width: 45% !important;
  /* box-shadow: 0 0 15px rgb(0 0 0 / 20%); */
}

@media only screen and (max-width: 900px) {
  .input-group {
    width: 70% !important;
  }
}

.navbar .input-group {
  width: 100% !important;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.searchInputHeader {
  width: 30%;
  justify-content: flex-start;
  margin-left: 8%;
  margin-top: 4px;
}

@media only screen and (max-width: 650px) {
  .searchInputHeader {
    width: 50%;
    justify-content: flex-start;
    margin-left: 8%;
  } 
}

.input-group .searchInput {
  /* width: 40% !important; */
  box-shadow: 0 0 15px rgb(0 0 0 / 20%);
  padding: 8px 9px 8px 20px;
  border-radius: 20px 0 0 20px;
}
.serchContainer {
  /* align-items: center; */
  /* margin-top: 0%; */
  /* margin-left: 0%; */
  width: 100%;
  text-align: -webkit-center;
}

.serchLable {
  /* color: ; */
  margin-left: 0%;
  margin-bottom: 2%;
}

.input-group .serchBtn {
  /* background: #ed7b38; */
  background: #ed681a;
  color: aliceblue;
  border-radius: 0 20px 20px 0;
  /* text-emphasis-color: white; */
}

.input-group .serchBtn:hover {
  background: #ed7b38;
  box-shadow: 0 0 15px rgb(0 0 0 / 20%);
}

.input-group .serchBtn:active {
  background: #ed7b38;
  box-shadow: 0 0 15px rgb(0 0 0 / 20%);
  /* color: aliceblue;
  border-radius: 0 20px 20px 0; */
  /* text-emphasis-color: white; */
}

.serchBtnHeader {
  background: #57d9d9;
  color: aliceblue;
  border-radius: 0px 5px 5px 0px;
}

.listContainer {
  /* margin-top: 100px; */
  padding-top: 95px;
  margin-bottom: 40px;
  /* justify-content: center; */
  text-align: -webkit-center;
  width: 100%;
  /* margin-left: 10%; */
}

@media only screen and (max-width: 992px) {
  .listContainer{
    text-align: -webkit-center;
    width: 100%;
    padding-top: 124px;
    margin-bottom: 40px;
  }
}

.tableTitle {
  color: gray;
  margin-left: 0%;
  margin-bottom: 30px;
}

.tableList {
   border: 1px solid grey;
   border-radius: 8px;
   box-shadow: 0 0 15px rgb(0 0 0 / 20%);
   width: 50%;
}

@media only screen and (max-width: 900px) {
  .tableList {
    width: 90%;
 }
}

@media only screen and (max-width: 1000px) and (min-width: 900px){
  .tableList {
    width: 70%;
 }
}
.tableList .tableHead {
  height: 60px;
  border-bottom: 2px solid grey;
  padding-bottom: 10px;
  justify-content: center;
}

.eyeButton{
   border: none;
   background: transparent;
}
/* eyeButton */
.mainContainer {
  /* max-height: 100%; */
  /* width: 100%; */
  /* position: absolute; */
  justify-content: 'center';
  display: flex;
  height: 90vh;
  width: 100%;
  align-items: center;
}

.mainContainerList {
  /* max-height: 100%; */
  /* width: 100%; */
  /* position: absolute; */
    justify-content: 'center';
    display: flex;
    height: 100vh;
    width: 100%;
    /* align-items: center; */
}

.pathDetailContainer {
  width: auto;
  padding: 30px;
}

.detailTitle {
  color: grey;
  margin-left: 2%;
  padding-top: 130px;
  margin-bottom: 10px;
}

.sortByselect {
  max-width: 80px;
  height: 38px;
}
.selectContainer {
  display: flex;
  position: relative;
  width: 50%;
  margin-right: 22%;
}

.paginationContainer {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-right: 10px;
  border-top: 1px solid grey;
  padding-top: 20px;
}

.sortLable {
  margin-top: 8px;
  margin-right: 11px;
  font-size: 14px;
  font-weight: 600;
}

.loaderContainer {
  /* z-index: 1000000000; */
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* padding-left: 50%; */
  /* padding-top: 21%; */
  position: fixed;
  display: 'flex';
  text-align: center;
  justify-content: 'center';
  align-items: 'center';
}

.loader {
  /* background-color: #6862624a; */
  background-color: #d8d6d64a;
  z-index: 1000000;
  height: 100%;
  width: 100%;
  position: fixed;
}

.loaderSpin{
  color: #ed681a;
}

.node-template {
  text-align: center;
 
  /* .strong {
    display: flex;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0.25em;
  }
 
  .span {
    display: flex;
    justify-content: center;
    padding: 0.25em;
  }
 
  .button {
    margin-bottom: 0.5em;
  } */
}
 
h1 {
  text-align: center;
  margin-bottom: 10px;
}
/* #efefef */
.hierarchy-viewer {
  background: #f3f3f3;
  display: block;
  margin: 20px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgb(0 0 0 / 20%);
}

@media only screen and (max-width: 900px) {
  .hierarchy-viewer {
    background: #f3f3f3;
    display: block;
    margin: 20px 20px 20px 13px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgb(0 0 0 / 20%);
  } 
}
 
.level1{
  background-color: rgb(231, 228, 255);
}
.level2{
  background-color: rgb(255, 237, 227);
}
.level3{
  background-color: rgb(242, 248, 227);
}

.react-hierarchy-node .node-line-horizontal {
  width: 40px;
}

.react-hierarchy-node-container {
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
}
.react-hierarchy-node-container-vertical {
  flex-direction: column;
}
.react-hierarchy-node-container .react-hierarchy-node {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.react-hierarchy-node-container .react-hierarchy-node .node-text-box {
  position: absolute;
  top: 0;
  transform: translate(40%, -50%);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.react-hierarchy-node-container .react-hierarchy-node:last-child .node-text-box {
  transform: translate(45%, -50%);
}

.react-hierarchy-node-container .react-hierarchy-node .node-marker {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.react-hierarchy-node-container .react-hierarchy-node .node-line {
  background-color: darkgrey;
}
.react-hierarchy-node-container .react-hierarchy-node .node-line-horizontal {
  width: 1px;
  height: 1px;
  flex: 1 auto;
}
.react-hierarchy-node-container .react-hierarchy-node .node-line-vertical {
  height: 1px;
  width: 1px;
}
.react-hierarchy-node-container .react-hierarchy-node .node-box {
  /* display: flex;
  align-items: center; */
  width: 1em;
  height: 1em;
  color: black;
  border-radius: 1em;
  box-shadow: 0.05em 0.05em 0.2em 0.05em rgba(0, 0, 0, 0.168627451);
}
.react-hierarchy-node-container .react-hierarchy-node-horizontal {
  padding: 1.5em 0;
  position: relative;
  width: 100%;
}

.react-hierarchy-node-container .react-hierarchy-node-horizontal-year {
  padding: 1.5em 0;
  position: relative;
  width: max-content; 
}
.react-hierarchy-node-container .react-hierarchy-node-vertical {
  flex-direction: column;
  padding: 0 1em;
}
.react-hierarchy-node-container .react-hierarchy-children {
  display: flex;
  flex: 1 auto;
}
.react-hierarchy-node-container .react-hierarchy-children-horizontal {
  flex-direction: column;
  width: 95%;
}
.react-hierarchy-node-container .react-hierarchy-children .children-container {
  display: flex;
}
.react-hierarchy-node-container .react-hierarchy-children .children-container:first-child .m-line {
  height: 0px;
  width: 0px;
}
.react-hierarchy-node-container .react-hierarchy-children .children-container:first-child > .lines-container .lines:first-child {
  background-color: transparent;
}
.react-hierarchy-node-container .react-hierarchy-children .children-container:last-child > .lines-container .lines:last-child {
  background-color: transparent;
}
.react-hierarchy-node-container .react-hierarchy-children .children-container-vertical {
  flex-direction: column;
}
.react-hierarchy-node-container .react-hierarchy-children .children-container .lines {
  flex: 1;
  background-color: darkgrey;
}
.react-hierarchy-node-container .react-hierarchy-children .children-container .lines-container {
  display: flex;
}
.react-hierarchy-node-container .react-hierarchy-children .children-container .lines-container-vertical .lines {
  height: 1px;
}
.react-hierarchy-node-container .react-hierarchy-children .children-container .lines-container-horizontal {
  flex-direction: column;
}
.react-hierarchy-node-container .react-hierarchy-children .children-container .lines-container-horizontal .lines {
  width: 1px;
  background-color: darkgrey;
}
.react-hierarchy-node-container .react-hierarchy-children .children-container .m-line {
  width: 1px;
  height: 1px;
  background-color: darkgrey;
}


.hierarchy-viewer {
  /* position: fixed;
  top: 50%;
  left: 0%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(0, -50%); */
  width: 98%;
  padding: 2%;
  overflow: hidden;
  overflow-y: auto;
}
.react-hierarchy-container {
  display: flex;
}
.react-hierarchy-container .react-hierarchy-root-horizontal {
  display: flex;
  width: 100%;
}
.react-hierarchy-container .ngx-hierarchy-border {
  border: 1px solid darkgrey;
}

.cursor {
  cursor: pointer;
}

.showmore {
  margin-left: 5px;
  font-size: 13px;
}
.textBox {
  margin-right: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /* -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; */
  /* text-overflow: ellipsis;  */
}

.yearTextBox {
  width: 60px;
  font-size: 12px;
  font-weight: 400;
  color: grey;
  margin-left: 3px;
}

.strong {
  font-size: 12px;
  font-weight: 400;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.strong .lastChild {
  font-size: 12px;
  font-weight: 550;
}


.rdt_TableHead{
  border-bottom: 2px solid grey;
  height: 65px;
}

.headerLogo {
  cursor: pointer;
  color: #000000;
  /* display: flex; */
}

/* .headerText {
  margin-top: 5px;
} */

.header-img {
  max-width: 40px;
  max-height: 40px;
  margin-left: 30px;
  margin-right: 8px;
}

@media only screen and (max-width: 300px) {
  .header-img {
    margin-left: 0px;
    margin-right: 5px;
  }
}

.react-hierarchy-node-container .react-hierarchy-children .children-container .lines-container-horizontal .linesCurvFirst {
  width: 20px;
  border-left: 1px solid darkgrey;
  border-top: 1px solid darkgray;
  background-color: transparent;
  border-radius: 50px 0px 0px 0px;
}

.react-hierarchy-node-container .react-hierarchy-children .children-container .lines-container-horizontal .linesCurvLast {
  width: 20px;
  border-left: 1px solid darkgrey;
  border-bottom: 1px solid darkgray;
  background-color: transparent;
  border-radius: 0px 0px 0px 50px;
  margin-top: 0.5px;
}

.react-hierarchy-node-container .react-hierarchy-children .children-container .lines-container-horizontal .m_lines {
  background-color: transparent;
}

.rdt_TableRow:nth-child(even) {
  background-color: #f3f3f3;
}

.rdt_TableRow {
  cursor: pointer;
}

.rdt_TableCell:nth-child(even) {
  justify-content: center;
}

.rdt_TableCol:nth-child(even) {
  justify-content: center;
}

.rdt_Pagination {
  border-radius: 0px 0px 8px 8px;
}

.navbar {
  width: 100%;
  position: fixed;
  z-index: 99;
}

.image_logo {
  max-width: 55px;
  max-height: 55px;
  margin-right: 10px;
}

.detailSearchBox {
  display: flex;
  width: 100%;
}

/* .detailSearchBox .input-group {
  width: 30% !important;
} */

.detailSearchBox .input-group {
  width: 35% !important;
}

.detailSearch {
  text-align: -webkit-right;
}

.detailSearchBox .btn {
  max-width: 100px;
  margin-left: 35px;
  box-shadow: 0 0 15px rgb(0 0 0 / 20%);
}

.noDataFound{
  margin-left: 43%;
}

.navbar .headerSearch{
  width: 100%;
  display: flex;
  position: relative;
}

.navbar .input-group {
  width: 50% !important;
}

@media only screen and (max-width: 900px) {
  .navbar .input-group {
    width: 90% !important;
  }
}

.navbar .navSearchBtn{
  background-color: #ed7b38;
  height: 38px;
}

.navbar .navSearchBtn i{
  color: white;
}


